import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Typography, Grid } from "@material-ui/core"
import CardLink from "../components/cardlink"

import { useStaticQuery, graphql } from "gatsby"

// import { makeStyles } from '@material-ui/core/styles'

// const useStyle = makeStyles({
//   root: {
//     'object-fit': 'contain',
//   },

// })

const IndexPage = () => {
  // const classes = useStyle()
  const data = useStaticQuery(graphql`
    query {
      officialImg: file(relativePath: { eq: "officialsite.jpg" }) {
        childImageSharp {
          fixed(width: 345) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pImg: file(relativePath: { eq: "proceeding-link.png" }) {
        childImageSharp {
          fixed(width: 345) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sImg: file(relativePath: { eq: "schedule.png" }) {
        childImageSharp {
          fixed(width: 345) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  // console.log(data)
  return (
    <Layout>
      <SEO title="Information" />
      <Box padding={2} textAlign="center">
        <Typography variant="overline" component="h1">
          Information about WWW 2020
        </Typography>
      </Box>
      <Grid container spacing={3} justify="center">
        <CardLink
          title="Official Website"
          description="Most of the information can be found in official website."
          picture={data.officialImg}
          url="https://www2020.thewebconf.org/"
        />
        <CardLink
          title="Proceedings"
          description="Proceedings and Companion proceedings for The Web Conference 2020."
          picture={data.pImg}
          url="/proceedings"
        />
        <CardLink
          title="Schedule"
          description="Schedule for program. Including Zoom link for online participants."
          picture={data.sImg}
          url="/schedule"
        />
      </Grid>
    </Layout>
  )
}

export default IndexPage
