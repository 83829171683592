import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import GImage from "gatsby-image"
import { Grid } from "@material-ui/core"
import W20Div from "../components/w20divider"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: "1rem auto",
  },
})

export default function ImgMediaCard({ picture, url, title, description }) {
  const classes = useStyles()

  return (
    <Grid item sm={6} md={4} xl={3}>
      <Card className={classes.root} variant="outlined">
        <CardActionArea href={url}>
          {!picture?.childImageSharp?.fixed ? (
            <></>
          ) : (
            <GImage
              fixed={picture.childImageSharp.fixed}
              title={title}
              alt={`img-${title}`}
            />
          )}
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <W20Div />
      </Card>
    </Grid>
  )
}
